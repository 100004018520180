/** @flow */
/* Transparent Navbar component that only renders a logo in the top left */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media, ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Box, Button } from '@eyeem-ui/atoms';

import Logo from './logo/';
import T from '../general/translate.jsx';
import Link from '../general/link/';
import { StyledLeftNavList } from './styles';

import withRouter from '../../helpers/hoc/withRouter.jsx';
import { SIGNUP_ROUTES, SIGNUP_AND_LOGIN_ROUTES } from '../../constants/misc';
import { routeMatchesOneOf } from '../../helpers/tools';
import baseTheme from '../../helpers/theme';

import { LOGIN_TRACKING, SIGNUP_TRACKING } from './navbarInfo/trackingStubs';

const StyledNav = styled.nav`
  position: relative;
  z-index: ${baseTheme.zIndices.navBar};
  height: ${(props) => props.theme.navHeight};
  margin-bottom: -${(props) => props.theme.navHeight};
  padding: 0 8px;

  @media ${media.mediumUp} {
    padding: 0 16px;
  }
  @media ${media.largeUp} {
    padding: 0 20px;
  }
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

function NavbarNaked(props: { isInExternalPage: boolean }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignUpOrLogin, setIsSignUpOrLogin] = useState(false);

  useEffect(() => {
    const path = props.getPath() || props.serverRoute;
    const isSignupRoute = routeMatchesOneOf(path, SIGNUP_ROUTES);
    const isSignupOrLoginRoute = routeMatchesOneOf(
      path,
      SIGNUP_AND_LOGIN_ROUTES
    );
    setIsSignUp(isSignupRoute);
    setIsSignUpOrLogin(isSignupOrLoginRoute);
  });

  return (
    <StyledNav>
      <StyledFlex
        justifyContent="space-between"
        alignItems="center"
        ml={[3, 0]}>
        <Box ml={[2, 0]}>
          <StyledLeftNavList>
            <ul>
              <Logo
                isBlack={!isSignUpOrLogin}
                isInExternalPage={props.isInExternalPage}
                eventPosition="navbar.eventPosition.naked"
              />
            </ul>
          </StyledLeftNavList>
        </Box>
        {isSignUp ? (
          <Box>
            <T {...LOGIN_TRACKING} component={Link} noTranslate href="/login">
              <ThemeProvider>
                <Button
                  variant="secondary"
                  text={<T>authForms.login.submit</T>}
                />
              </ThemeProvider>
            </T>
          </Box>
        ) : null}
        {!isSignUp && isSignUpOrLogin ? (
          <Box>
            <T {...SIGNUP_TRACKING} component={Link} noTranslate href="/signup">
              <ThemeProvider>
                <Button
                  variant="secondary"
                  text={<T>loginSignup.signupSubmit</T>}
                />
              </ThemeProvider>
            </T>
          </Box>
        ) : null}
      </StyledFlex>
    </StyledNav>
  );
}

export default withRouter(NavbarNaked);
