/* @flow */
/* eslint-env browser */
import { useEffect } from 'react';
import classnames from 'classnames'; // eslint-disable-line
import t from 'counterpart';
import get from 'lodash/get';

import ContentLeftImageRight from '../general/contentLeftImageRight/';
import T from '../general/translate.jsx';

import { preventAndStop } from '../../helpers/tools';

import { getOpenIdAuthUrl } from '../../clientAPI/AuthAPI';

const redirectToOpenId = (
  payload: OpenIdAuthUrlPayload & {
    showError: Function,
  }
) =>
  getOpenIdAuthUrl({
    provider: payload.provider,
    type: payload.type,
    intent: payload.intent,
  })
    .then((res) => res.body)
    .then((body) => {
      window.location = body.authUrl;
    })
    .catch((err) =>
      payload.showError(
        get(err, 'response.body.message') ||
          t('authForms.openId.errors.generic')
      )
    );

function OpenIdLogin(props: {
  provider: string,
  type: string,
  showNotification: Function,
}) {
  useEffect(() => {
    props.showNotification({
      notification: 'openIdLoginError',
      customCopy: t('authForms.openId.errors.generic'),
    });
  }, []);
  return (
    <ContentLeftImageRight
      moveBehindNavbar
      verticallyCentered
      srcSet
      backgroundImageUrl="/node-static/img/landing/mlp/imageBreaks/1-enterprise.jpg">
      <div className="row">
        <div
          className={classnames(
            'column text-centered',
            'small-12',
            'medium-8 medium-offset-2',
            'large-6 large-offset-3',
            'xlarge-8 xlarge-offset-2'
          )}>
          <T className="typo_headline3">authForms.openId.headline</T>
          <T
            provider={t(`authForms.openId.providers.${props.provider}`)}
            className="typo_p typo-color-grey3">
            authForms.openId.subline
          </T>
          <T
            eventAction={t('tracking.eventAction.button')}
            eventName={t('authForms.openId.button.eventName')}
            eventLabel={t('authForms.openId.button.label')}
            eventPosition={t('authForms.openId.eventPosition')}
            onClick={preventAndStop({
              callback: redirectToOpenId,
              payload: {
                provider: props.provider,
                type: props.type,
                showError: (message) =>
                  props.showNotification({
                    notification: 'openIdLoginError',
                    customCopy: message,
                  }),
              },
            })}
            component="button"
            type="submit"
            className="typo_img g_btn g_btn-aqua g_btn-large g_btn-fullWidth">
            authForms.openId.button.label
          </T>
        </div>
      </div>
    </ContentLeftImageRight>
  );
}

export default OpenIdLogin;
