import { connect } from 'react-redux';

import { getBreakpointName, getDevicePixelRatio } from '../../../reducers/';

import ContentLeftImageRight from './contentLeftImageRight.jsx';

const mapStateToProps = (state) => ({
  breakpointName: getBreakpointName(state),
  devicePixelRatio: getDevicePixelRatio(state),
});

export default connect(mapStateToProps)(ContentLeftImageRight);
