/* @flow */
import type { Node } from 'react';
import styled from 'styled-components';
import { styleUtils } from 'eyeem-components';
import { media } from '@eyeem-ui/theme';
import { getSrcSetUrl } from '../../../helpers/tools';

const minHeight = (props) =>
  styleUtils.fromPropsTernary(
    'moveBehindNavbar',
    '100vh',
    `calc(100vh - ${styleUtils.fromTheme('navHeight')(props)})`
  )(props);

const marginTop = (props) =>
  styleUtils.fromPropsTernary(
    'moveBehindNavbar',
    `-${styleUtils.fromTheme('navHeight')(props)}`,
    '0'
  )(props);

const Wrapper = styled.div`
  min-height: ${minHeight};
  margin-top: ${marginTop};
  overflow: scroll;
  width: 100%;

  @media ${media.xlargeUp} {
    background-image: url('${styleUtils.fromProps('backgroundImageUrl')}');
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const Content = styled.div`
  background-color: ${styleUtils.fromTheme('colors.whites.white')};
  width: 100%;
  min-height: ${minHeight};
  display: table;
  padding-left: 20px;
  padding-right: 20px;

  @media ${media.mediumUp} {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media ${media.xlargeUp} {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
    margin: 0;
    width: 540px;
  }
`;

const Inner = styled.div`
  min-height: ${minHeight};
  width: 100%;
  display: table-cell;
  padding: 56px 0;
  vertical-align: ${styleUtils.fromPropsTernary(
    'verticallyCentered',
    'middle',
    'baseline'
  )};

  @media ${media.mediumUp} {
    padding: 96px 0;
  }
`;

function ContentLeftImageRight(props: {
  verticallyCentered?: boolean,
  srcSet?: boolean,
  moveBehindNavbar?: boolean,
  backgroundImageUrl: string,
  breakpointName: string,
  devicePixelRatio: number,
  children: Node,
}) {
  return (
    <Wrapper
      moveBehindNavbar={props.moveBehindNavbar}
      backgroundImageUrl={
        props.srcSet
          ? getSrcSetUrl(
              props.backgroundImageUrl,
              props.breakpointName,
              props.devicePixelRatio
            )
          : props.backgroundImageUrl
      }>
      <Content moveBehindNavbar={props.moveBehindNavbar}>
        <Inner
          moveBehindNavbar={props.moveBehindNavbar}
          verticallyCentered={props.verticallyCentered}>
          {props.children}
        </Inner>
      </Content>
    </Wrapper>
  );
}

ContentLeftImageRight.defaultProps = {
  verticallyCentered: false,
  srcSet: false,
  moveBehindNavbar: false,
};

export default ContentLeftImageRight;
